import { ReactNode } from 'react'
import { toast } from 'sonner'

import { i18nInstance } from './i18n'

export const showToast = (children: ReactNode) => {
  toast(children)
}

export const showNetWorkErrToast = () => {
  toast.error(
    i18nInstance.resolvedLanguage === 'en'
      ? 'request failed. please check your network'
      : '请求失败，请检查网络'
  )
}

export const showServerErrToast = () => {
  toast.error(
    i18nInstance.resolvedLanguage === 'en'
      ? 'request failed, please try again later '
      : '请求失败，请稍后重试'
  )
}
