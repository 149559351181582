import { Session } from '@supabase/supabase-js'
import { NuqsAdapter } from 'nuqs/adapters/next/pages'
import React from 'react'

import { AuthProvider } from './auth'
import { HistoryManagerProvider } from './history-manager/HistoryManagerProvider'
import { I18nProvider } from './i18n'
import { PostHogProvider } from './posthog/PostHogProvider'
import { QueryClientProvider } from './react-query'
import { SafeAreaProvider } from './safe-area'
import { ToastProvider } from './toast'
export function Provider({
  initialSession,
  children,
}: {
  initialSession?: Session | null
  children: React.ReactNode
}) {
  return (
    <AuthProvider initialSession={initialSession}>
      <Providers>{children}</Providers>
    </AuthProvider>
  )
}

const compose = (providers: React.FC<{ children: React.ReactNode }>[]) =>
  providers.reduce((Prev, Curr) => ({ children }) => {
    const Provider = Prev ? (
      <Prev>
        <Curr>{children}</Curr>
      </Prev>
    ) : (
      <Curr>{children}</Curr>
    )
    return Provider
  })

const Providers = compose([
  I18nProvider,
  SafeAreaProvider,
  ToastProvider,
  QueryClientProvider,
  HistoryManagerProvider,
  PostHogProvider,
  NuqsAdapter,
])
