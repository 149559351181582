import { Toaster } from 'sonner'

export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      {children}
      <Toaster position="top-right" richColors duration={3000} closeButton />
    </>
  )
}
