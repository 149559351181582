import { SpeedInsights } from '@vercel/speed-insights/next'
import { Provider } from 'app/provider'
import { AuthProviderProps } from 'app/provider/auth'
import { api } from 'app/utils/api'
import { NextPage } from 'next'
import { DM_Sans, Darker_Grotesque } from 'next/font/google'
import Head from 'next/head'
import Script from 'next/script'
import { PagesProgressBar as ProgressBar } from 'next-nprogress-bar'
import { DefaultSeo } from 'next-seo'
import 'raf/polyfill'
import { ReactElement, ReactNode } from 'react'
require('../public/reset.css')

const dmSans = DM_Sans({
  subsets: ['latin'],
  variable: '--font-dm-sans',
})

const darkerGrotesque = Darker_Grotesque({
  subsets: ['latin'],
  variable: '--font-darker-grotesque',
})

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
  //https://stackoverflow.com/questions/59124463/nextjs-how-to-not-unmount-previous-page-when-going-to-next-page-to-keep-state
}

const APP_NAME = 'MyDreamBoy'
const APP_DESCRIPTION = 'Create The Man Of Your Dreams!'

function MyApp({
  Component,
  pageProps,
}: {
  Component: NextPageWithLayout
  pageProps: { initialSession: AuthProviderProps['initialSession'] }
}) {
  // reference: https://nextjs.org/docs/pages/building-your-application/routing/pages-and-layouts
  const getLayout = Component.getLayout || ((page) => page)

  return (
    <>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <style global jsx>{`
        :root {
          --font-dm-sans: ${dmSans.style.fontFamily};
          --font-darker-grotesque: ${darkerGrotesque.style.fontFamily};
        }
        html {
          font-family: var(--font-dm-sans);
        }
      `}</style>
      <Head>
        <link rel="icon" href="/favicon.svg" />
        <meta name="application-name" content={APP_NAME} />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content={APP_NAME} />
        <meta name="description" content={APP_DESCRIPTION} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="theme-color" content="#050505" />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        />
        <link rel="manifest" href="/manifest.json" />
        <link rel="canonical" href="https://www.mydreamboy.com/" />
      </Head>
      <DefaultSeo
        title="Pick your AI boyfriend | Meet Your Dream Boy"
        description="MyDreamBoy is the best AI Porn Generator for Male. Our cutting-edge AI Porn Generator offers a seamless way to create highly realistic AI-generated pornographic art and images, featuring hundreds of NSFW/SFW tags for personalized content creation."
        additionalMetaTags={[
          {
            property: 'keywords',
            content:
              'ai boyfriend, ai boys, ai boy, ai gay, ai man, ai men, ai male, ai daddy, ai generated, nsfw ai image generator, ai porn, ai sex, ai sexy, ai nude, ai generated porn, ai generator, ai image generator, adult ai, gay porn, ai porn generator, ai images, best ai porn prompts, custom ai boy, ai porn images, boy ai generator, aiboy, create boy ai images, boy ai image generator, aiporn, make porn, ai porn pictures, ai porn free, realistic ai, undress ai',
          },
        ]}
      />
      <main className={`${dmSans.variable} ${darkerGrotesque.variable} font-sans`}>
        <Provider initialSession={pageProps.initialSession}>
          {getLayout(<Component {...pageProps} />)}
        </Provider>
        <ProgressBar
          height="3px"
          color="hsl(206, 100%, 50.0%)"
          options={{ showSpinner: true }}
          shallowRouting
        />
      </main>
      <Script
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        src="https://um.mydreamboy.com/script.js"
        data-website-id={
          process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
            ? '04d1ec70-265a-4395-b03f-f2da6108fb7f'
            : '9a8599ed-3095-4689-8e62-2ddac7ddabc5'
        }
      />
      <SpeedInsights />
      {/* <!-- Trackdesk tracker begin --> */}
      <Script async src="https://cdn.trackdesk.com/tracking.js" />
      <Script
        dangerouslySetInnerHTML={{
          __html:
            '(function(t,d,k){(t[k]=t[k]||[]).push(d);t[d]=t[d]||t[k].f||function(){(t[d].q=t[d].q||[]).push(arguments)}})(window,"trackdesk","TrackdeskObject");trackdesk("mydreamboy", "click");',
        }}
      />
      {/* <!-- Trackdesk tracker end --> */}
    </>
  )
}

export default api.withTRPC(MyApp)
